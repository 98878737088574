import { makeStyles, alpha } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    root: {
      minHeight: '100vh',
      paddingTop: 65,
    },
    topBar: {
      height: 65,
      backgroundColor: '#021c4b',
      padding: theme.spacing(1, 4),
    },
    headingRow: {},
    pageHeading: {
      fontWeight: 'bold',
    },
    surveyTabs: {
      backgroundColor: '#fbfbfb',
      marginBottom: theme.spacing(6),
    },
    logo: {
      width: 97,
      height: 44,
    },
    user: {},
    add: {
      color: '#4b93fd',
      fontWeight: 'bold',
      fontSize: 12,
      cursor: 'pointer',
    },
    typeTab: {
      fontWeight: 400,
      cursor: 'pointer',
      position: 'relative',
      color: 'rgba(51, 51, 51, 0.5)',
      letterSpacing: 0.24,
      width: 220,
      '&:first-child': {
        '&:before': {
          content: '""',
          position: 'absolute',
          width: 1,
          height: '55%',
          top: 0,
          bottom: 0,
          right: 0,
          margin: 'auto',
          backgroundColor: 'rgba(151, 151, 151, .25)',
        },
      },
    },
    activeTabclass: {
      fontWeight: '900',
      color: 'rgba(51, 51, 51, 1)',
      '&:after': {
        content: '""',
        height: '5px',
        width: '60%',
        backgroundColor: '#f28165',
        borderRadius: '2.5px',
        position: 'absolute',
        left: 0,
        right: 0,
        margin: 'auto',
        bottom: 0,
      },
    },
    surveyTable: {
      marginTop: theme.spacing(3),
      minWidth: 650,
      boxShadow: '4px 2px 4px 3px rgba(235, 235, 235, 0.5)',
    },
    tableHeadCell: {
      backgroundColor: 'rgba(75, 147, 253, 0.15)',
      color: '#1e1e1e',
      fontWeight: 700,
      fontSize: 14,
      '&:first-child': {
        paddingLeft: theme.spacing(6),
      },
      borderRightStyle: 'solid',
      borderRightWidth: '2px',
      borderRightColor: alpha('#979797', 0.15),
      borderBottom: 'none',
    },
    newsurveybtn: {
      fontSize: '14px',
      fontWeight: 700,
      color: '#4b93fd',
      textTransform: 'uppercase',
    },
    tableBodyCell: {
      borderRightStyle: 'solid',
      borderRightWidth: '2px',
      borderRightColor: alpha('#979797', 0.15),
      borderBottom: 'none',
      '&:first-child': {
        paddingLeft: theme.spacing(7),
      },
    },
    buttonProgress: {
      position: 'absolute',
      left: -3,
      top: 6,
    },
    warningctrl: {
      display: 'flex',
      alignItem: 'center'
    },
    warningbtn: {
      display: 'flex',
     
    },
    warniAtag:{
      whiteSpace: 'nowrap',
      marginLeft: 15
    },
    warningtext: {
      textAlign: 'left',
      paddingRight: 80,
      color: '#000'
    }
  }
})

export default styles

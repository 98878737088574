import React, { useEffect, useState, memo } from 'react'
import useStyles from './styles'
import {
  Box,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  createTheme,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core'
import validateInclusion from '../../../../validations/inclusion'

import { ThemeProvider } from '@material-ui/styles'

//component
const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#021c4b',
    },
    secondary: {
      main: '#4b93fd',
    },
  },
})

function EditInclusion({
  hideDeepdiveEdit,
  inclusiondata,
  inlusiondataStaus,
  onUpdateInclusion,
}) {
  const classes = useStyles()

  const [inclusion, setInfInclusiondata] = useState({
    type: '2',
  })

  const [loading, setLoading] = React.useState(false)
  const [msg, setMsg] = useState('')

  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (inclusiondata.get('isFetched')) {
      setInfInclusiondata(inclusiondata.toJS().items)
    }
  }, [inclusiondata])
  useEffect(() => {
    if (inlusiondataStaus.get('isFetched')) {
      setMsg(inlusiondataStaus.get('items'))
      setLoading(false)
      /* setLoading(false)
        addToast(influencerStatus.get('items').get('data'), {
          appearance: influencerStatus.get('items').get('error')
            ? 'error'
            : 'success',
          autoDismiss: true,
        }) */

      if (!inlusiondataStaus.get('items').get('error')) {
        hideDeepdiveEdit()
      }
    }
  }, [inlusiondataStaus])
  //console.log(influencerdata)
  function handleInputChangeinf(name) {
    return function(event) {
      setInfInclusiondata({ ...inclusion, [name]: event.target.value })
    }
  }

  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      className={classes.root}
    >
      <ThemeProvider theme={outerTheme}>
        <Box className="overflowAuto">
          <Typography
            variant="h1"
            component="h2"
            className={classes.sectionHeading}
          >
            Inclusion
          </Typography>
          <Box className={classes.subtitle}>
            Note: your Inclusion open-ended questions will appear below when you
            "Save" at the bottom of the main page."
          </Box>

          {/*<FormLabel component="legend" className={classes.qtypelable}>*/}
          {/*  Inclusion Module Version 1*/}
          {/*</FormLabel>*/}
          <RadioGroup
            aria-label="type"
            name="type"
            value={`${inclusion.type}`}
            style={{ marginBottom: 20, marginTop: 20 }}
            onChange={handleInputChangeinf('type')}
            row
          >
            <FormControlLabel
              classes={{
                label: classes.label,
              }}
              style={{ marginBottom: '0' }}
              value="3"
              control={
                <Radio
                  color="primary"
                  classes={{
                    checked: classes.checked,
                  }}
                />
              }
              label="70 Question version"
              labelPlacement="end"
            />
            <FormControlLabel
              classes={{
                label: classes.label,
              }}
              style={{ marginBottom: '0' }}
              value="4"
              control={
                <Radio
                  color="primary"
                  classes={{
                    checked: classes.checked,
                  }}
                />
              }
              label="Inclusion with additional work outcome questions"
              labelPlacement="end"
            />

            {/*<FormLabel component="legend" className={classes.qtypelable}>*/}
            {/*  Inclusion Module Version 1*/}
            {/*</FormLabel>*/}
            {/*<FormControlLabel*/}
            {/*  classes={{*/}
            {/*    label: classes.label,*/}
            {/*  }}*/}
            {/*  value="1"*/}
            {/*  control={*/}
            {/*    <Radio*/}
            {/*      color="primary"*/}
            {/*      classes={{*/}
            {/*        checked: classes.checked,*/}
            {/*      }}*/}
            {/*      disabled*/}
            {/*    />*/}
            {/*  }*/}
            {/*  label="9 Question version"*/}
            {/*  labelPlacement="end"*/}
            {/*/>*/}
            {/*<FormControlLabel*/}
            {/*  classes={{*/}
            {/*    label: classes.label,*/}
            {/*  }}*/}
            {/*  value="2"*/}
            {/*  control={*/}
            {/*    <Radio*/}
            {/*      color="primary"*/}
            {/*      classes={{*/}
            {/*        checked: classes.checked,*/}
            {/*      }}*/}
            {/*      disabled*/}
            {/*    />*/}
            {/*  }*/}
            {/*  label="34 Question version"*/}
            {/*  labelPlacement="end"*/}
            {/*/>*/}

            {errors.type && (
              <FormHelperText
                id="my-helper-text-type"
                className={classes.myhelptext}
              >
                {errors.type}
              </FormHelperText>
            )}
          </RadioGroup>
        </Box>
      </ThemeProvider>
      {renderBottom()}
    </Box>
  )

  function renderBottom() {
    function cancelButton() {
      hideDeepdiveEdit()
      setTimeout(function() {
        setInfInclusiondata(inclusiondata.toJS().items)
      }, 200)
    }
    function saveInclusion() {
      setErrors(validateInclusion(inclusion))
      if (Object.keys(validateInclusion(inclusion)).length === 0) {
        setLoading(true)
        onUpdateInclusion(inclusion)
      }

      //console.log(inclusion)
    }
    return (
      <Box className={classes.footer}>
        <Box className={classes.btnWrapper}>
          <Button
            color="primary"
            disabled={loading}
            variant="contained"
            className={classes.buttonRound}
            onClick={saveInclusion}
          >
            Save
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonRound}
            onClick={cancelButton}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    )
  }
}

export default memo(EditInclusion)

import { makeStyles, alpha } from '@material-ui/core/styles'
import sortIcon from '../../assets/sort-icon.svg'
const styles = makeStyles(theme => {
  return {
    root: {},
    surveyTable: {
      marginTop: theme.spacing(3),
      minWidth: 650,
      boxShadow: '4px 2px 4px 3px rgba(235, 235, 235, 0.5)',
    },
    tableHeadCell: {
      backgroundColor: alpha('#4b93fd', 0.1),
      color: '#1e1e1e',
      fontWeight: 700,
      fontSize: 12,
      '&:first-child': {
        // paddingLeft: theme.spacing(6),
      },
      borderRightStyle: 'solid',
      borderRightWidth: '2px',
      borderRightColor: alpha('#979797', 0.15),
      borderBottom: 'none',
      position: 'relative',
      lineHeight: '1.5',
    },
    sortable: {
      cursor: 'pointer',
    },
    sortASC: {
      '&:after': {
        content: '""',
        backgroundImage: `url(${sortIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        transform: 'translatey(-50%)',
        width: theme.spacing(2),
        height: theme.spacing(2),
        top: '50%',
        right: theme.spacing(1),
        position: 'absolute',
      },
    },
    sortDESC: {
      '&:after': {
        content: '""',
        backgroundImage: `url(${sortIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        transform: 'translatey(-50%)',
        width: theme.spacing(2),
        height: theme.spacing(2),
        top: '50%',
        right: theme.spacing(1),
        position: 'absolute',
      },
    },
    tableBodyCell: {
      borderRightStyle: 'solid',
      borderRightWidth: '2px',
      borderRightColor: alpha('#979797', 0.15),
      borderBottom: 'none',
      '&:first-child': {
        paddingLeft: theme.spacing(7),
        cursor: 'pointer',
      },
      fontSize: 12,
    },
    tableBodyCell1: {
      borderRightStyle: 'solid',
      borderRightWidth: '2px',
      borderRightColor: alpha('#979797', 0.15),
      borderBottom: 'none',
      paddingLeft: theme.spacing(7),
      cursor: 'pointer',
      color: '#4b93fd',
      fontWeight: 700,
      fontSize: 12,
      '& div': {
        display: 'inline',
        fontWeight: 'normal',
      },
    },
    viewAll: {
      color: '#4b93fd',
      cursor: 'pointer',
    },
    listAnalysts: {
      padding: theme.spacing(1),
    },
    listItemAnalysts: {
      borderBottom: '1px solid #e7e7e7',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    analystsText: {
      fontSize: 12,
      lineHeight: '2.5',
    },
    buttonRound: {
      borderRadius: '25px',
      fontWeight: '400',
      fontSize: 14,
    },
    cstSelect: {
      '&:focus': {
        backgroundColor: 'transparent !important',
      },
      '& div:focus': {
        backgroundColor: 'transparent !important',
      },
    },
  }
})

export default styles

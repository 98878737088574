import { red } from '@material-ui/core/colors'
import { createTheme, createStyles, alpha } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: [
      '"Mckinsey Sans"',
      '"Lato"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Oxygen"',
      '"Ubuntu"',
      '"Cantarell"',
      '"Fira Sans"',
      '"Droid Sans"',
      '"Helvetica Neue"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    text: {
      // primary: '#fff',
    },
    primary: {
      main: '#4b93fd',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  overrides: {
    MuiTableRow: createStyles({
      root: {
        '&:nth-child(even)': {
          backgroundColor: alpha('#4b93fd', 0.04),
        },
      },
    }),
    MuiFab: createStyles({
      root: {
        boxShadow: 'none',
      },
    }),
    MuiButton: createStyles({
      contained: {
        boxShadow: 'none',
      },
    }),
    MuiSelect: createStyles({
      select: {
        color: '#999',
        fontSize: 11,
      },
      icon: {
        color: '#000',
      },
    }),
    MuiMenuItem: createStyles({
      root: {
        fontSize: 12,
      },
    }),
    MuiInputBase: createStyles({
      input: {
        color: '#333333',
      },
    }),
    MuiInput: createStyles({
      underline: {
        '&:before': {
          borderBottomColor: '#e7e7e7',
        },
      },
    }),
    MuiFormLabel: createStyles({
      root: {
        color: '#999999',
      },
    }),
    MuiDialog: createStyles({
      root: {
        // zIndex: '9999 !important',
      },
    }),
    MuiTootip: createStyles({
      root: {
        backgroundColor: '#ffffff',
      },
      tooltip: {
        maxWidth: 'auto',
      },
      tooltipPlacementRight: {
        backgroundColor: '#ffffff',
      },
      popper: {
        backgroundColor: '#ffffff',
      },
    }),
    MuiChip: createStyles({
      clickable: {
        '&:active': {
          backgroundColor: '#f28165 !important',
          color: '#fff !important',
          border: '1px solid #f28165',
        },
        '&:focus': {
          backgroundColor: '#f28165 !important',
          color: '#fff !important',
          border: '1px solid #f28165',
        },
        '&:hover': {
          backgroundColor: '#f28165 !important',
          color: '#fff !important',
          border: '1px solid #f28165',
        },
      },
    }),
  },
})

// console.log(theme)
export default theme
